import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueScrollShow from 'vue-scroll-show'
 
Vue.use(VueScrollShow)

import VueTagManager from "vue-tag-manager"
Vue.use(VueTagManager, {
  gtmId: 'GTM-WDXJKSV'
})

import VueTyperPlugin from 'vue-typer'
Vue.use(VueTyperPlugin)

import AOS from 'aos';
AOS.init();

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
