<template>
    <div>

        <carousel :autoplay="true" :loop="true" :autoplayHoverPause="false" :perPageCustom="[[1,1], [640,2], [1024,3]]" :autoplayTimeout="5000" :navigationEnabled="false" :paginationEnabled="true">

            <slide class="flex flex-col px-6 pb-4">
                <div class="-mb-20">
                    <img src="/images/client-01.png" alt="Solid Horizon Sdn Bhd" class="w-[145px] h-auto mx-auto">
                </div>
                <div class="p-[30px] pt-[100px] rounded-xl shadow-md text-center Xh-full">
                    <p class="text-sm leading-loose montserrat-reg lg:min-h-[168px]">
                        ‘‘The day you engaged with Activa Media, that’s the day your business changed. Activa Media has helped to transform our online marketing model by providing advice and suggestions for better outcome.’’
                    </p>
                    <div class="border-t-4 border-[#4678B4] w-10 mx-auto mt-5"></div>
                    <h3 class="text-base italic am-blue montserrat-bol mt-5">
                        Mr James Yeoh,<br>
                        Managing Director,
                    </h3>
                    <h3 class="italic montserrat-reg">Solid Horizon Sdn Bhd</h3>
                </div>
            </slide>

            <slide class="flex flex-col px-6 pb-4">
                <div class="-mb-20">
                    <img src="/images/client-02.png" alt="GD Express Sdn Bhd (GDEX)" class="w-[145px] h-auto mx-auto">
                </div>
                <div class="p-[30px] pt-[100px] rounded-xl shadow-md text-center Xh-full">
                    <p class="text-sm leading-loose montserrat-reg lg:min-h-[168px]">
                        ‘‘Activa Media helps GDEX expand its awareness online. They tried their best to fulfill business requirements. Kelvin and the team are very professional. We look forward to expand more digital marketing.’’
                    </p>
                    <div class="border-t-4 border-[#4678B4] w-10 mx-auto mt-5"></div>
                    <h3 class="text-base italic am-blue montserrat-bol mt-5">
                        Mr Edmund Teong,<br>
                        Business Development,
                    </h3>
                    <h3 class="italic montserrat-reg">GD Express Sdn Bhd (GDEX)</h3>
                </div>
            </slide>

            <slide class="flex flex-col px-6 pb-4">
                <div class="-mb-20">
                    <img src="/images/client-03.png" alt="Goldbell Equipment Sdn Bhd" class="w-[145px] h-auto mx-auto">
                </div>
                <div class="p-[30px] pt-[100px] rounded-xl shadow-md text-center Xh-full">
                    <p class="text-sm leading-loose montserrat-reg lg:min-h-[168px]">
                        ‘‘We have experienced a significant growth in leads and conversion to sales since the day Activa Media come on board. Activa Media is the company that I trust deeply to have the expertise.’’
                    </p>
                    <div class="border-t-4 border-[#4678B4] w-10 mx-auto mt-5"></div>
                    <h3 class="text-base italic am-blue montserrat-bol mt-5">
                        Mr Ang Yoon Ping,<br>
                        Country General Manager,
                    </h3>
                    <h3 class="italic montserrat-reg">Goldbell Equipment Sdn Bhd</h3>
                </div>
            </slide>

            <slide class="flex flex-col px-6 pb-4">
                <div class="-mb-20">
                    <img src="/images/client-04.png" alt="Benima Marketing Sdn Bhd" class="w-[145px] h-auto mx-auto">
                </div>
                <div class="p-[30px] pt-[100px] rounded-xl shadow-md text-center Xh-full">
                    <p class="text-sm leading-loose montserrat-reg lg:min-h-[168px]">
                        ‘‘Activa Media serve great and always able to quickly react and adjust ads to industry trends. The google campaign did well and growing our online business.’’
                    </p>
                    <div class="border-t-4 border-[#4678B4] w-10 mx-auto mt-5"></div>
                    <h3 class="text-base italic am-blue montserrat-bol mt-5">
                        Ms Ally Teh,<br>
                        Finance Controller,
                    </h3>
                    <h3 class="italic montserrat-reg">Benima Marketing Sdn Bhd</h3>
                </div>
            </slide>

            <slide class="flex flex-col px-6 pb-4">
                <div class="-mb-20">
                    <img src="/images/client-05.png" alt="Dynaloc Sdn Bhd" class="w-[145px] h-auto mx-auto">
                </div>
                <div class="p-[30px] pt-[100px] rounded-xl shadow-md text-center Xh-full">
                    <p class="text-sm leading-loose montserrat-reg lg:min-h-[168px]">
                        ‘‘We have been with Activa Media since year 2014 and do well pleased for their professionality. Would recommend this company for their effort on constantly follow up and cost effective.’’
                    </p>
                    <div class="border-t-4 border-[#4678B4] w-10 mx-auto mt-5"></div>
                    <h3 class="text-base italic am-blue montserrat-bol mt-5">
                        Ms Chow Li Chen,<br>
                        Managing Director,
                    </h3>
                    <h3 class="italic montserrat-reg">Dynaloc Sdn Bhd</h3>
                </div>
            </slide>

            <!-- <slide class="flex flex-col px-6 pb-4">
                <div class="-mb-20">
                    <img src="/images/client-06.png" alt="Big Bath Sdn Bhd" class="w-[145px] h-auto mx-auto">
                </div>
                <div class="p-[30px] pt-[100px] rounded-xl shadow-md text-center Xh-full">
                    <p class="text-sm leading-loose montserrat-reg lg:min-h-[168px]">
                        ‘‘Activa Media had been our online marketing partner since 2013 , we are happy with their service and the results that we had achieved with them .We would recommend Activa Media to everyone that is looking for online marketing service to be your professional partner towards your business success.’’
                    </p>
                    <div class="border-t-4 border-[#4678B4] w-10 mx-auto mt-5"></div>
                    <h3 class="text-base italic am-blue montserrat-bol mt-5">
                        Mr Shane Mun,<br>
                        Managing Director,
                    </h3>
                    <h3 class="italic montserrat-reg">Big Bath Sdn Bhd</h3>
                </div>
            </slide> -->

            <slide class="flex flex-col px-6 pb-4">
                <div class="-mb-20">
                    <img src="/images/client-07.png" alt="MH Global Sdn Bhd" class="w-[145px] h-auto mx-auto">
                </div>
                <div class="p-[30px] pt-[100px] rounded-xl shadow-md text-center Xh-full">
                    <p class="text-sm leading-loose montserrat-reg lg:min-h-[168px]">
                        ‘‘I strongly recommend Activa Media to anyone who would like to incur in the digital marketing world. We are glad to have them to handle our Marketing on digital marketing world.’’
                    </p>
                    <div class="border-t-4 border-[#4678B4] w-10 mx-auto mt-5"></div>
                    <h3 class="text-base italic am-blue montserrat-bol mt-5">
                        Mr Mustafa Hussain,<br>
                        Director,
                    </h3>
                    <h3 class="italic montserrat-reg">MH Global Sdn Bhd</h3>
                </div>
            </slide>

            <!-- <slide class="flex flex-col px-6 pb-4">
                <div class="-mb-20">
                    <img src="/images/client-09.png" alt="Sumi-Tech Industrial Supplies Sdn Bhd" class="w-[145px] h-auto mx-auto">
                </div>
                <div class="p-[30px] pt-[100px] rounded-xl shadow-md text-center Xh-full">
                    <p class="text-sm leading-loose montserrat-reg lg:min-h-[168px]">
                        “We’ve been partner with Activa Media since 2010, and they are definitely a professional digital agency. We’re satisfied with their services and we definitely see this as a long-term solution.”
                    </p>
                    <div class="border-t-4 border-[#4678B4] w-10 mx-auto mt-5"></div>
                    <h3 class="text-base italic am-blue montserrat-bol mt-5">
                        Mr Ng Boon Lian,<br>
                        Managing Director,
                    </h3>
                    <h3 class="italic montserrat-reg">Sumi-Tech Industrial Supplies Sdn Bhd</h3>
                </div>
            </slide> -->

        </carousel>


    </div>  
</template>

<script>

export default {

  }
</script>

<style>

</style>