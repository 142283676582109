<template>
    <div class="container px-4 mx-auto">
        <div class="py-5 text-xs sm:text-sm bg-white border-solid sm:text-center text-blck montserrat-reg">
            <p class="lg:mb-5 lg:leading-none">
                Website maintained by Activa Media. <span class="inline-block">All rights reserved.</span>
            </p>
        </div>

    </div>  
</template>

<script>
export default {

}
</script>

<style>

</style>