<template>
  <div class="mx-auto overflow-hidden text-sm maxWidth lg:text-base">
    <!-- Whatsapp -->
    <!-- <Whatsappbtn /> -->

    <!-- Header -->
    <div
      class="section-header border-t-[10px] border-activa-blue-2 w-full duration-500 z-50 absolute py-5 sm:py-4"
    >
      <div class="container mx-auto px-4 lg:px-[85px]">
        <div
          class="flex flex-col flex-wrap items-center justify-center sm:flex-row sm:justify-between"
        >
          <div class="w-auto">
            <img
              src="/images/logo.png"
              alt="Activa Media"
              class="mb-5 pr-0 md:mb-0 sm:pr-10 h-[60px] lg:h-auto"
            />
          </div>
          <!-- <div> -->
          <!-- Mobile View -->
          <!-- <div class="flex flex-wrap items-center justify-center">
              <a
                href="#"
                v-scroll-to="{ el: '#element', duration: 1500 }"
                class="block w-40 py-2 mx-auto text-sm tracking-widest text-center text-white bg-blue-900 border-2 border-blue-900 border-solid rounded-full mar-bottom sm:mb-0 lg:mr-6 lg:hidden montserrat-reg"
                >ENQUIRE NOW</a
              >
              <a
                href="tel:+60391332398"
                id="cta-top-mobile"
                class="block w-40 py-2 mx-auto text-center bg-white border-2 border-blue-400 border-solid rounded-full lg:hidden montserrat-bol am-blue"
                ><i class="fas fa-phone-alt"></i> 03-91332398</a
              >
            </div> -->
          <!-- </div> -->
          <div class="flex flex-wrap">
            <!-- <a
              href="#"
              v-scroll-to="{ el: '#element', duration: 1500 }"
              class="block w-40 py-2 mr-6 text-sm tracking-widest text-center text-white border-2 border-white border-solid rounded-full montserrat-reg"
              >ENQUIRE NOW</a
            > -->
            <a
              href="https://wa.me/60197738780?text=I%20would%20like%20to%20enquire%20on%20Digital%20Marketing%20Services%20for%20my%20business"
              id="cta-top-desktop"
              class="block px-6 py-2 text-sm lg:text-base text-center rounded-full montserrat-bol am-blue border-[#4678b4] lg:border-0 bg-white border-2"
              style="letter-spacing: 1px;"
            >
              <!-- <i class="fas fa-phone-alt"></i> -->
              Whatsapp to enquire
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="justify-center lg:flex lg:flex-wrap">
      <div
        class="bg-left-bottom bg-no-repeat bg-contain lg:w-10/12 lg:h-screen bg-banner-wrap"
        style="background-image: url(../images/bg-activa.png)"
      >
        <div class="XpaddingLeft lg:absolute lg:w-full">
          <div class="container mx-auto px-4 lg:px-[85px]">
            <div
              class="pt-[230px] pb-10 lg:pt-[138px] montserrat-bol lg:pb-40 lg:h-screen lg:tracking-[-1px] lg:w-7/12 bg-banner-inner"
            >
              <h2
                class="mb-[5px] text-3xl leading-tight lg:leading-tight md:text-4xl lg:text-[40px] xl:text-5.5xl"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Performance-Driven
              </h2>
              <h1
                class="text-4xl leading-none md:text-5xl lg:pr-16 xl:text-[70px] am-blue lg:leading-none mb-5 lg:mb-10"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                Digital
                <span class="lg:block">Marketing</span>
                Agency
              </h1>
              <h2
                class="mb-5 text-xl leading-tight lg:text-[32px]"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1000"
              >
                Generate Qualified Leads
                <span class="lg:block">& Build Lifetime Value</span>
              </h2>
              <p
                class="mb-10 text-base tracking-normal lg:text-xl lg:leading-normal montserrat-reg xl:mb-4"
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="2000"
              >
                Since 2009, we’ve been helping companies <br class="" />achieve
                <b>
                  <vue-typer
                    :text="[
                      'digital excellence',
                      'continuous growth',
                      'proven results',
                    ]"
                    :repeat="Infinity"
                    :shuffle="false"
                    initial-action="typing"
                    :pre-type-delay="70"
                    :type-delay="70"
                    :pre-erase-delay="2000"
                    :erase-delay="70"
                    erase-style="backspace"
                    :erase-on-complete="false"
                    caret-animation="blink"
                  >
                  </vue-typer>
                </b>
              </p>
              <!-- partner icon -->
              <div class="pb-4 lg:py-6">
                <img src="/images/partners-logo.png" alt="" class="w-full md:w-auto md:h-auto">
              </div>
              <!-- <div class="flex flex-wrap items-center w-4/5 px-1 py-6 md:w-1/2 lg: lg:w-2/3 xl:w-80">
                <div class="w-1/3">
                  <a
                    href="https://www.google.com/partners/agency?id=4481818645"
                    target="_blank"
                    class=""
                  >
                    <img
                      src="https://www.gstatic.com/partners/badge/images/2023/PremierBadgeClickable.svg"
                      class=""
                    />
                  </a>
                </div>
                <div class="w-1/3">
                  <img
                    class=""
                    src="/images/meta-business-partner.png"
                    alt="Meta Business Partner"
                  />
                </div>
                <div class="w-1/3">
                  <img
                    class=""
                    src="/images/tiktok-agency-partner.png"
                    alt="Tiktok Agency Partner"
                  />
                </div>
              </div> -->
              <!-- enquiry button  -->
              <div
                class="space-y-10 md:flex md:items-center lg:space-y-0"
              >
                <a
                  v-scroll-to="{ el: '#get-in-touch-with-us', duration: 1500 }"
                  class="block w-60 px-5 py-[13px] mt-[2px] mx-auto text-[15px] tracking-[3px] text-center text-white uppercase bg-[#3cb4f0] rounded-full md:mx-0 lg:mx-0 montserrat-med cursor-pointer"
                  >ENQUIRE NOW</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="hidden bg-center bg-no-repeat bg-cover md:w-11/12 lg:block"
        style="background-image: url(../images/bg-header-02.jpg)"
      >
        <!-- Desktop View -->
        <!-- <div class="flex flex-wrap pt-10 pl-16">
          <a
            href="#"
            v-scroll-to="{ el: '#element', duration: 1500 }"
            class="block w-40 py-2 mr-6 text-sm tracking-widest text-center text-white border-2 border-white border-solid rounded-full montserrat-reg"
            >ENQUIRE NOW</a
          >
          <a
            href="tel:+60391332398"
            id="cta-top-desktop"
            class="block w-40 py-2 text-center bg-white rounded-full montserrat-bol am-blue"
            ><i class="fas fa-phone-alt"></i> 03-91332398</a
          >
        </div> -->
      </div>
    </div>
    <!-- Header End -->

    <!-- How We Help You -->
    <div class="pt-10 lg:pt-20 pb-5 lg:pb-[60px] bg-[#E6F5FA]">
      <div class="container mx-auto px-4 lg:px-[85px]">
        <h2
          class="mb-5 text-3xl text-center lg:text-[40px] lg:text-left lg:leading-tight montserrat-bol lg:tracking-[-1px]"
        >
          How We <span class="am-blue">Help You</span>
        </h2>
        <p class="mb-10 text-center lg:px-0 lg:text-left montserrat-reg">
          Your goals are our goals. With a combination of our experience, reach
          and array of services, we make digital marketing easy for you.
        </p>
        <div
          class="grid-cols-3 mb-5 md:grid md:justify-center gap-x-[30px] space-y-10 lg:space-y-0"
        >
          <div
            class=""
            v-for="(item, i) in helpU"
            :key="i"
            :data-aos="item.aniMation"
            :data-aos-delay="item.deLay"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1200"
          >
            <div class="flex items-center mb-5 lg:block lg:mb-0 lg:space-y-5">
              <img
                class="w-auto h-20 lg:h-auto mr-[10px] lg:mr-0"
                :src="item.image"
                :alt="item.alt"
              />
              <p class="text-xl lg:text-2xl lg:leading-[1.25] montserrat-bol">
                {{ item.alt }}
              </p>
            </div>
            <p class="montserrat-reg lg:mt-5">{{ item.sub }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- How We Help You End -->

    <!-- Our Specialties -->
    <div
      id="our-specialities"
      class="pt-10 lg:pt-20 pb-5 lg:pb-[60px] scroll-mt-32"
    >
      <div class="container mx-auto px-4 lg:px-[85px] lg:text-center">
        <h2
          class="mb-5 text-3xl lg:text-[40px] lg:text-left lg:leading-tight montserrat-bol lg:tracking-[-1px]"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          Our <span class="am-blue">Specialties</span>
        </h2>
        <p class="mb-10 lg:text-left montserrat-reg">
          Activa Media specialises in providing online advertising using Google,
          Facebook and Tik Tok. We tailor each campaign with bulletproof
          strategies for measurable results and lead generation. Find out more
          how you can increase your revenue with our methods to stellar online
          advertising.
        </p>

        <div class="grid lg:grid-cols-3 mb-5 gap-x-[30px] gap-y-10 text-center">
          <div class="">
            <div
              class="specialty-content"
              data-aos="fade-right"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
            >
              <img class="mx-auto mb-5" src="/images/logo-specialty-01.jpg" />
              <h4
                class="montserrat-bol text-xl lg:text-2xl lg:leading-[1.25] lg:pt-[3px]"
              >
                Search Engine <span class="lg:block">Marketing (SEM)</span>
              </h4>
            </div>
          </div>
          <div class="">
            <div
              class="specialty-content"
              data-aos="fade-right"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <img class="mx-auto mb-5" src="/images/logo-specialty-02.jpg" />
              <h4
                class="montserrat-bol text-xl lg:text-2xl lg:leading-[1.25] lg:pt-[3px]"
              >
                Social Media <span class="lg:block">Marketing (SMM)</span>
              </h4>
            </div>
          </div>
          <div class="">
            <div
              class="specialty-content"
              data-aos="fade-right"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
              data-aos-delay="600"
            >
              <img class="mx-auto mb-5" src="/images/logo-specialty-03.jpg" />
              <h4
                class="montserrat-bol text-xl lg:text-2xl lg:leading-[1.25] lg:pt-[3px]"
              >
                Search Engine <span class="lg:block">Optimization (SEO)</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Our Specialties End -->

    <!-- There’s no better time -->
    <!-- <div class="mx-auto maxWidth">
      <div class="justify-center lg:flex lg:flex-wrap">
        <div
          class="h-64 bg-center bg-no-repeat bg-cover lg:w-1/2 lg:h-auto"
          style="background-image: url(../images/bg-start.jpg)"
        ></div>
        <div class="py-16 pl-12 text-white lg:w-1/2 am-blue-bg">
          <div class="pr-6 xl:w-3/5 lg:w-3/5 lg:pr-0">
            <h1
              class="text-4xl leading-tight montserrat-bol"
              data-aos="fade-right"
            >
              There’s no better time than now.
            </h1>
            <br />
            <p class="pr-6 montserrat-reg lg:pr-0" data-aos="fade-right">
              In marketing, time lost is compounded as your competitors move
              ahead of you. Each customer you lose is one they gain.
              <br /><br />
              That’s why it’s important to reach them – fast! We’re ready to
              help you get there.
              <br /><br />
              <span class="font-bold"
                >Arrange for a video call with our specialists, and you’ll be
                provided with a fully customized, no-obligations plan of action
                for your business to grow.</span
              >
            </p>
            <br /><br />
            <a
              href="#"
              v-scroll-to="{ el: '#element', duration: 1500 }"
              data-aos="fade-up"
              class="block w-56 px-5 py-3 text-sm tracking-widest text-center text-white uppercase bg-blue-400 rounded-full montserrat-reg"
              >LET’S GET STARTED</a
            >
          </div>
        </div>
      </div>
    </div> -->
    <!-- There’s no better time End -->

    <!-- Transition CTA -->
    <div class="py-[60px] text-center bg-[#f5f8fa]">
      <div class="container mx-auto">
        <div class="">
          <h3
            class="mb-5 text-3xl lg:text-[40px] lg:leading-tight montserrat-bol lg:tracking-[-1px]"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <span class="am-blue">Creating a positive experience</span> is our
            top priority.
          </h3>
          <div
            class="mb-5 montserrat-reg"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="250"
          >
            <p>Get your free personalised action plan now.</p>
          </div>
          <div class="">
            <a
              class="inline-block px-5 py-[15px] mx-auto text-sm lg:text-[15px] tracking-[3px] text-center text-white uppercase bg-[#3cb4f0] rounded-full md:mx-0 lg:mx-0 montserrat-med cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
              style="margin-bottom:10px"
              v-scroll-to="{ el: '#get-in-touch-with-us', duration: 1500 }"
            >
              ENQUIRE NOW
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Transition CTA End -->

    <!-- Success Stories and counting -->
    <div
      id="about-us"
      class="pt-10 lg:pt-20 pb-5 lg:pb-[60px] am-blue-bg scroll-mt-32"
    >
      <div class="container mx-auto px-4 lg:px-[85px]">
        <div
          class="items-center justify-center lg:grid grid-cols-2 gap-x-[30px]"
        >
          <div class="space-y-5 montserrat-bol">
            <h2 class="text-5xl conterfont am-blue-dark lg:tracking-[-1px]">
              <CounterCount />
            </h2>
            <p
              class="text-3xl lg:text-4xl lg:leading-tight montserrat-bol lg:tracking-[-1px] text-white"
            >
              Success Stories <span class="am-blue-dark">and counting</span>
            </p>
            <p
              class="lg:!mt-10 text-2xl lg:text-[28px] lg:leading-tight text-white"
            >
              Trusted by the best in the business
            </p>
            <div class="text-white montserrat-reg space-y-5 !mb-6">
              <p>
                Over the past 14 years, we’ve helped thousands of businesses
                launch more than 6,500 campaigns.
              </p>
              <p>
                With an intimate understanding of your business, industry and
                competitors, we craft campaigns that set you apart from the rest
                of the competition.
              </p>
            </div>
          </div>
          <div class="lg:pl-[45px] mb-5">
            <div
              class="flex items-center text-white border-b border-white border-solid xcusWidth"
              v-for="(item, i) in industry"
              :key="i"
            >
              <img :src="item.image" :alt="item.alt" class="col-auto" />
              <p
                class="pl-5 py-[15px] w-full text-base lg:text-xl montserrat-med leading-[30px]"
              >
                {{ item.alt }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Success Stories and counting End -->

    <!-- Testi -->
    <div class="pt-10 lg:pt-20 pb-5 lg:pb-[60px]">
      <div class="container mx-auto px-4 lg:px-[85px]">
        <h2
          class="mb-5 text-3xl lg:text-[40px] lg:leading-tight montserrat-bol lg:tracking-[-1px] text-center"
        >
          What Our <span class="am-blue">Clients Say</span>
        </h2>
        <br />
        <TheTesti />
      </div>
    </div>
    <!-- Testi End -->

    <!-- Why Choose Us -->
    <div
      id="why-clients-choose-us"
      class="pt-10 lg:pt-20 pb-10 lg:pb-[60px] bg-[#F5F8FA] scroll-mt-32"
    >
      <div class="container mx-auto px-4 lg:px-[85px]">
        <ChooseUs class="" />
      </div>
    </div>
    <!-- Why Choose Us End -->

    <!-- Enquiry  -->
    <div
      id="get-in-touch-with-us"
      class="pt-10 lg:pt-20 pb-5 lg:pb-[60px] bg-[#E6F5FA] scroll-mt-32"
    >
      <div class="container mx-auto px-4 lg:px-[85px] text-center space-y-5">
        <h2
          class="text-3xl lg:text-[40px] lg:leading-tight montserrat-bol lg:tracking-[-1px]"
          data-aos="zoom-in-down"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          Guaranteed Results
          <span class="am-blue">in 90 Days Or Less<sup>^</sup></span>
        </h2>
        <div
          class="space-y-5"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1500"
          data-aos-delay="600"
        >
          <p class="text-sm montserrat-reg lg:text-base">
            Drop us a message and we’ll get back to you within 1 working day.
          </p>
          <p class="text-sm italic montserrat-reg lg:text-base">
            <sup>^</sup>Guarantee varies between services
          </p>
        </div>
        <div class="enquiry">
          <div id="xcustomHeight" class="mx-auto">
            <!-- <EnquiryForm /> -->
            <!-- feedback.activamedia.com.sg script begins here --><iframe
              allowTransparency="true"
              class="h-0 min-h-[520px] sm:min-h-[450px]"
              style="overflow: auto"
              width="100%"
              id="contactform123"
              name="contactform123"
              marginwidth="0"
              marginheight="0"
              frameborder="0"
              src="https://feedback.activamedia.com.sg/my-contact-form-5680221.html"
              loading="lazy"
              ><p>
                Your browser does not support iframes. The contact form cannot
                be displayed. Please use another contact method (phone, fax etc)
              </p></iframe
            ><!-- feedback.activamedia.com.sg script ends here -->
          </div>
        </div>
      </div>
    </div>
    <!-- Enquiry End -->

    <!-- Contact Us -->
    <div class="pt-10 lg:pt-20 pb-5 lg:pb-[60px] am-dark-bg">
      <div class="container mx-auto px-4 lg:px-[85px]">
        <div class="items-center justify-center mb-5 md:flex md:flex-wrap">
          <div class="mb-5 md:w-1/2 lg:mb-0">
            <img
              class="w-full h-auto"
              width="916"
              height="830"
              src="/images/image-office.jpg"
              alt="AMMY Office"
            />
          </div>
          <div class="space-y-5 text-white md:pl-4 lg:pl-16 md:w-1/2">
            <div class="flex items-center gap-x-8">
              <img
                src="/images/logo-white.png"
                alt="Activa Media"
                class="mx-auto lg:mx-0"
              />
              <a
                href="https://www.google.com/partners/agency?id=4481818645"
                target="_blank"
              >
                <img
                  class="w-auto h-[120px]"
                  height="120px"
                  src="https://www.gstatic.com/partners/badge/images/2023/PremierBadgeClickable.svg"
                />
              </a>
            </div>
            <p class="text-sm lg:text-base montserrat-reg">
              Unit 21-03, Level 21, Menara MBMR,
              <span class="block">No.1 Jalan Syed Putra, 58000</span>
              <span class="block">Kuala Lumpur, Malaysia</span>
            </p>
            <div class="">
              <a
                href="https://wa.me/60197738780?text=I%20would%20like%20to%20enquire%20on%20Digital%20Marketing%20Services%20for%20my%20business"
                id="wa-btn-bottom"
                class="block text-sm montserrat-reg lg:text-base"
              >
                <i class="fab fa-whatsapp"></i> 019-7738780
              </a>
              <a
                href="tel:+60391332398"
                id="cta-bottom"
                class="block text-sm montserrat-reg lg:text-base"
              >
                <i class="fas fa-building"></i> 03-91332398
              </a>
              <a
                href="mailto:enquiry@activamedia.com.my"
                class="block text-sm montserrat-reg lg:text-base"
              >
                <i class="fas fa-envelope"></i> enquiry@activamedia.com.my
              </a>
            </div>
            <iframe
              class="!mt-[30px] md:w-[310px] md:h-[177px]"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.90052746021!2d101.67680851522124!3d3.1210062977273907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc35c56d6bff89%3A0x2736cc9fcb365ebc!2sActiva%20Media%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1602646715336!5m2!1sen!2smy"
              width="100%"
              height="300"
              frameborder="0"
              style="border:0;"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <!-- Contact Us End -->

    <!-- Coryright -->
    <CopyRight />
    <!-- Copyright end -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Vue from "vue";
import AOS from "aos";
import Whatsappbtn from "@/components/Whatsappbtn.vue";
import MobileHeader from "@/components/ChooseUs.vue";
import ChooseUs from "@/components/ChooseUs.vue";
import TheTesti from "@/components/TheTesti.vue";
import CounterCount from "@/components/CounterCount.vue";
import CopyRight from "@/components/CopyRight.vue";
// import EnquiryForm from "@/components/EnquiryForm.vue"

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

export default {
  name: "Home",
  components: {
    // HelloWorld
    Whatsappbtn,
    MobileHeader,
    ChooseUs,
    TheTesti,
    CounterCount,
    CopyRight,
    // EnquiryForm
  },
  data() {
    return {
      activeSticky: false,
      helpU: [
        {
          aniMation: "fade-up",
          deLay: "0",
          image: "images/how-number-1b.jpg",
          alt: "Qualified Traffic",
          sub:
            "Great marketers know how to target people who are truly interested. We help you create meaningful relationships with your customers and become top of mind.",
        },
        {
          aniMation: "fade-up",
          deLay: "300",
          image: "images/how-number-2b.jpg",
          alt: "Guaranteed Leads",
          sub:
            "No more canvassing, no more cold-calling – we optimize your funnel for maximum lead generation. We help convert your viewers into your customers.",
        },
        {
          aniMation: "fade-up",
          deLay: "600",
          image: "images/how-number-3b.jpg",
          alt: "Proven ROI",
          sub:
            "Digital marketing maximizes your return on investment. With a heavy focus on converting the right people, we bring in customers that can, will and want to spend.",
        },
      ],
      industry: [
        {
          image: "images/icon-industry-01.png",
          alt: "Dental",
        },
        {
          image: "images/icon-industry-02.png",
          alt: "Printing",
        },
        {
          image: "images/icon-industry-03.png",
          alt: "Courier Services",
        },
        {
          image: "images/icon-industry-04.png",
          alt: "Movers",
        },
        {
          image: "images/icon-industry-05.png",
          alt: "Renovation",
        },
        {
          image: "images/icon-industry-06.png",
          alt: "Automotive",
        },
      ],
    };
  },
  methods: {
    handleScroll() {
      // console.log(window.scrollY)
      this.activeSticky = window.scrollY >= 200 ? true : false;
      AOS.refresh();
    },
  },
  mounted() {
    document.addEventListener("scroll", this.handleScroll);
    // window.addEventListener('load', ()=>{
    //   setTimeout(AOS.refresh(),1000);
    // });
  },
};
</script>

<style>
.scroll-mt-32 {
  scroll-margin-top: 8rem;
}

/* Font Family */
.montserrat-reg {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
.montserrat-med {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.montserrat-bol {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

/* Max Width */
.maxWidth {
  max-width: 1920px;
}

/* Header Style */
.am-blue {
  color: #4678b4;
}
.am-blue-bg {
  background: #4678b4;
}
.am-dark-bg {
  background: #0f1455;
}
.mar-bottom {
  margin-bottom: 2%;
}

/* counter font size */
.am-blue-dark {
  color: #0f1455;
}

/* Industry */
.cusWidth {
  width: 300px;
}

/* responsive */
@media (min-width: 512px) {
  .mar-bottom {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  #customHeight iframe {
    min-height: 490px !important;
  }
}
@media (min-width: 1024px) {
  .conterfont {
    font-size: 120px;
  }
  .paddingLeft {
    padding-left: 10%;
  }
  .bg-banner-wrap,
  .bg-banner-inner {
    min-height: 840px;
  }
  /* enquiry form */
  /* .enquiry iframe {
    min-height: 550px !important;
  } */
}

@media (min-width: 1280px) {
  .bg-banner-wrap,
  .bg-banner-inner {
    min-height: 890px;
  }
}

@media (min-width: 1500px) {
  .paddingLeft {
    padding-left: 34.53%;
  }
}
</style>
